<template>
  <div class="auth-wrapper auth-v1 pa-0">
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            <!-- Reset Password 🔒 -->
            {{ $t('ResetPassword.reset_password') }}
          </p>
          <p class="mb-2">
            <!-- Your new password must be different from previously used passwords -->
            {{ $t('ResetPassword.reset_password_description') }}
          </p>
        </v-card-text>

        <!-- Reset form -->
        <v-card-text>
          <v-form
            ref="form"
            @submit.prevent="handleFormSubmit"
          >
            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              :label="$t('ResetPassword.form.password')"
              :placeholder="$t('ResetPassword.form.password_placeholder')"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details="auto"
              class="mb-3"
              :error-messages="errorMessages.password"
              :rules="[required]"
              :disabled="submitMessage != ''"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <v-text-field
              v-model="confirmPassword"
              outlined
              :type="isConfirmPasswordVisible ? 'text' : 'password'"
              :label="$t('ResetPassword.form.confirm_password')"
              :placeholder="$t('ResetPassword.form.confirm_password_placeholder')"
              :append-icon="isConfirmPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details="auto"
              :error-messages="errorMessages.confirmPassword"
              :rules="[required]"
              :disabled="submitMessage != ''"
              @click:append="isConfirmPasswordVisible = !isConfirmPasswordVisible"
            ></v-text-field>

            <v-btn
              class="mt-4"
              block
              type="submit"
              color="primary"
              :disabled="submitMessage != ''"
            >
              <div v-if="loading">
                <v-progress-circular
                  indeterminate
                  color="red"
                  @click.prevent="onCancelRequest"
                >
                  <v-icon>
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-progress-circular>
              </div>
              <!-- Set New Password -->
              <span v-else>{{ $t('ResetPassword.form.submit') }}</span>
            </v-btn>
          </v-form>
          <div>
            <v-alert
              v-if="submitMessage != ''"
              v-height-fade.appear
              fade
              :type="submitMessageType"
              class="mb-0 mt-2"
            >
              <div class="alert-body">
                <span>{{ submitMessage }}</span>
              </div>
            </v-alert>
          </div>
        </v-card-text>

        <!-- back to login -->
        <v-card-actions class="d-flex justify-center align-center">
          <router-link
            :to="{name:'auth-login'}"
            class="d-flex align-center text-sm"
          >
            <v-icon
              size="24"
              color="primary"
            >
              {{ icons.mdiChevronLeft }}
            </v-icon>
            <span>{{ $t('ResetPassword.back_to_login') }}</span>
          </router-link>
        </v-card-actions>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiChevronLeft, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'

import { required } from '@core/utils/validation'

// Services
// import useJwt from '@/services/api/modules/auth/useJwt'
import useAuthHandler from '@/views/pages/auth/useAuthHandler'

export default {
  setup() {
    // Uses
    const { sendAuthNotification } = useAuthHandler()
    const { route } = useRouter()

    // Data
    const password = ref('')
    const confirmPassword = ref('')
    const errorMessages = ref([])
    const submitMessage = ref('')
    const submitMessageType = ref('success')
    const isPasswordVisible = ref(false)
    const isConfirmPasswordVisible = ref(false)

    // Token validation
    // eslint-disable-next-line no-unused-vars
    const onValidationToken = queryToken => new Promise((resolve, reject) => {
      submitMessageType.value = 'error'
      submitMessage.value = `El token ha caducado, vuelva a solicitarlo: ${queryToken}`

      resolve(queryToken)
    })
    const token = ref(route.value.query.token)
    onValidationToken(token.value)

    // Form
    const form = ref(null)
    const loading = ref(false)

    // Events
    const handleFormSubmit = () => {
      const isFormValid = form.value.validate()

      if (!isFormValid) return

      // eslint-disable-next-line no-use-before-define
      onResetPassword()
        .then(response => {
          sendAuthNotification(response.message, 'success')
          submitMessage.value = 'Success'
        })
        .catch(error => {
          // sendAuthNotification(error.message, 'error')
          // errorMessages.value = error.error

          if (error.response && error.response.data && error.response.data.error) {
            errorMessages.value = error.response.data.error

            sendAuthNotification(error.response.data.error.message, 'error')
          }
        })
    }

    // eslint-disable-next-line no-unused-vars
    const onResetPassword = () => new Promise((resolve, reject) => {
      loading.value = !loading.value
      errorMessages.value = []

      // ok
      resolve(loading)

      // Error
      // const error = {
      //   message: 'Check your email',
      //   error: {
      //     username: 'Check your email',
      //   },
      // }
      // reject(error)
    })

    const onCancelRequest = () => new Promise(resolve => {
      loading.value = false
      resolve()
    })

    return {
      // Form validation
      form,
      handleFormSubmit,
      required,

      // Data
      password,
      confirmPassword,
      errorMessages,
      submitMessage, // Mensaje de confirmación de la solicitud
      submitMessageType,
      isPasswordVisible,
      isConfirmPasswordVisible,

      // Interface events
      loading,
      onCancelRequest,
      onResetPassword, // No se usa en el Template

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiChevronLeft,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
